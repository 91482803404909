import {
 ADD_ALERT,
 REMOVE_ALERT
} from "./constants";

// **  Initial State
const initialState = {
  show: false,
  color: "",
  message: ""
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return {
       show: true,
       color: action.color,
       message: action.msg
      };
    case REMOVE_ALERT:
      return {
        show: false,
        color:"",
        message: ""
      };
    default:
      return state;
  }
};

export default alertReducer;
