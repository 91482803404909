
export const SAVE_SMS_FORM_INFO = "SAVE_SMS_FORM_INFO"
export const GET_ALL_SMS_CAMPAIGN = "GET_ALL_SMS_CAMPAIGN"
export const ADD_NEW_SMS_CAMPAIGN_LOAD = "ADD_NEW_SMS_CAMPAIGN_LOAD"
export const ADD_NEW_SMS_CAMPAIGN_ERROR = "ADD_NEW_SMS_CAMPAIGN_ERROR"
export const ADD_NEW_SMS_CAMPAIGN_SUCCESS = "ADD_NEW_SMS_CAMPAIGN_SUCCESS"
export const UPDATE_SMS_CAMPAIGN = "UPDATE_SMS_CAMPAIGN"
export const DELETE_SMS_CAMPAIGN = "DELETE_SMS_CAMPAIGN"
export const SMS_COMPAIGN_ERROR = "SMS_COMPAIGN_ERROR" 
export const SMS_COMPAIGN_TEST_SUCCESS = "SMS_COMPAIGN_TEST_SUCCESS" 
export const SMS_COMPAIGN_TEST_FAILURE = "SMS_COMPAIGN_TEST_FAILURE" 
export const SMS_COMPAIGN_TEST_LOADING = "SMS_COMPAIGN_TEST_LOADING" 