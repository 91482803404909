export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const GET_ALL_TEMPLATES_SUCCESS ="GET_ALL_TEMPLATES_SUCCESS";
export const GET_ALL_TEMPLATES_LOADING ="GET_ALL_TEMPLATES_LOADING";
export const RESET_SAVED_EMAIL_TEMPLATE = "RESET_SAVED_EMAIL_TEMPLATE"
export const GET_SAVED_TEMPLATE = "GET_SAVED_TEMPLATE";
export const DELETE_TEMPLATE="DELETE_TEMPLATE";
export const ADD_NEW_TEMPLATE = "ADD_NEW_TEMPLATE";

export const DUPLICATE_EMAIL_TEMPLATE_SUCCESS = "DUPLICATE_EMAIL_TEMPLATE_SUCCESS";

export const TEST_TEMPLATE_SUCCESS = "TEST_TEMPLATE_SUCCESS";