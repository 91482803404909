import {
  LOGOUT_USER_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_RESET,
  ADD_USER_LOADING,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  GET_ALL_USER_LOADING,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_FAILED,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  GET_USER_DATA
} from "./constants";

// **  Initial State
const initialAuthState = {
  allUsers: [],
  userData: {},
  user:JSON.parse(localStorage.getItem("userData")) || {},
  success: false,
};

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case USER_LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userData: action.data,
      };
    case USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGIN_RESET:
      return {
        ...initialAuthState,
      };
    // ADD USERS
    case ADD_USER_LOADING:
      return {
        ...state,
        addUserLoading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserLoading: false,
        success: true,
        allUsers: [...state.allUsers, action.payload]
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        addUserLoading: false,
        error: action.payload,
      };
    // GET ALL USERS
    case GET_ALL_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: [...action.payload],
      };
    case GET_ALL_USER_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    // DELETE USER
    case DELETE_USER_LOADING:
      return {
        ...state,
        deleteLoading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        success: true,
        allUsers: state.allUsers.filter(el => el.id !== action.payload.id)
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload,
      };

    //Update User
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        success: true,
        allUsers: state.allUsers.map(ele => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          }
          else return ele;
        })
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserLoading: false,
        error: action.payload,
      };
    case LOGOUT_USER_SUCCESS:
      return { ...state, userData: {} };
    default:
      return state;
  }
};

const initialResetState = {
  loading: false,
  success: false,
};

export const resetReducer = (state = initialResetState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_RESET:
      return {
        ...initialResetState,
      };
    default:
      return state;
  }
};
