// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import {authReducer, resetReducer} from './auth'
import navbar from './navbar'
import layout from './layout'
import alert from './alert'
import integration from './integration'
import email from './email'
import journey from "./journey"
import push from "./push"
import sms from "./sms"
import feedback from './feedback'
import segment from "./segments"
import emailCampaign from "./emailCampaign"
import {usersListReducer} from './userslist/index'
import smsCampaign from './smsCampaign'
import pushCampaign from './pushCampaign'
import emailCredential from "./emailCredentials"

const rootReducer = combineReducers({
  auth : authReducer,
  resetPassword: resetReducer,
  navbar,
  layout,
  alert,
  integration,
  email,
  journey,
  push,
  sms,
  feedback,
  segment,
  emailCampaign,
  smsCampaign,
  pushCampaign,
  usersList: usersListReducer,
  emailCredential
})

export default rootReducer
