import {
  GET_ALL_NOTIFICATION_LOADING,
  GET_ALL_NOTIFICATION_SUCCESS,
  ADD_NEW_NOTIFICATION,
  DELETE_NOTIFICATION,
  SAVE_NOTIFICATION_STATE,
  UPDATE_NOTIFICATION
} from "./constants";


// **  Initial State
const initialState = {
  notificationList: [],
  prevState: {}
};

const pushNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: action.notificationData
      };

    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        notificationList: [...state.notificationList, action.payload]
      }
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationList: state.notificationList.filter(el => el.id !== action.payload)
      }
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        notificationList: state.notificationList.map(ele => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          }
          else return ele;
        })
      }
    }
    default:
      return state;
  }
};

export default pushNotificationReducer;