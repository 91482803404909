import {
  GET_ALL_FEEDBACK_SUCCESS,
  GET_ALL_FEEDBACK_LOADING,
  UPDATE_FEEDBACK_FAILED, UPDATE_FEEDBACK_LOADING, UPDATE_FEEDBACK_RESET, UPDATE_FEEDBACK_SUCCESS,
} from "./constants";


// **  Initial State
const initialState = {
  feedbackList: [],
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FEEDBACK_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_ALL_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackList: action.feedbackList
      };
    case UPDATE_FEEDBACK_LOADING:
      return {
        ...state,
        update_loading: true
      }
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        update_loading: false,
        update_success: true
      }
    case UPDATE_FEEDBACK_FAILED:
      return {
        ...state,
        update_loading: false,
        update_success: false,
        update_error: action.payload
      }
    case UPDATE_FEEDBACK_RESET:
      return {
        ...state,
        update_loading: false,
        update_success: false,
        update_error: ""
      }
    default:
      return state;
  }
};

export default feedbackReducer;