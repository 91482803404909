import {
  GET_ALL_SMS_SUCCESS,
  GET_ALL_SMS_LOADING,
  ADD_NEW_SMS,
  DELETE_SMS,
  UPDATE_SMS
} from "./constants";


// **  Initial State
const initialState = {
  smsList: []
};

const smsNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SMS_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_ALL_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        smsList: action.smsData
      };

    case ADD_NEW_SMS:
      return {
        ...state,
        smsList: [...state.smsList, action.payload]
      }
    case UPDATE_SMS: {
      return {
        ...state,
        smsList: state.smsList.map(ele => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          }
          else return ele;
        })
      }
    }
    case DELETE_SMS:
      return {
        ...state,
        smsList: state.smsList.filter(el => el.id !== action.payload)
      }

    default:
      return state;
  }
};

export default smsNotificationReducer;