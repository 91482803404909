import {
  GET_ALL_CREDENTIAL_LOADING,
  GET_ALL_CREDENTIAL_SUCCESS,
  ADD_NEW_CREDENTIAL,
  DELETE_CREDENTIAL,
  UPDATE_CREDENTIAL
} from "./constants";


// **  Initial State
const initialState = {
  credentialsList: [],
};

const credentialReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CREDENTIAL_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_ALL_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        credentialsList: action.credentialData
      };

    case ADD_NEW_CREDENTIAL:
      return {
        ...state,
        credentialsList: [...state.credentialsList, action.payload]
      }
    case DELETE_CREDENTIAL:
      return {
        ...state,
        credentialsList: state.credentialsList.filter(el => el.id !== action.payload)
      }
    case UPDATE_CREDENTIAL: {
      return {
        ...state,
        credentialsList: state.credentialsList.map(ele => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          }
          else return ele;
        })
      }
    }
    default:
      return state;
  }
};

export default credentialReducer;