export const SAVE_FORM_INFO = "SAVE_FORM_INFO"
export const GET_ALL_CAMPAIGN = "GET_ALL_CAMPAIGN"
export const ADD_NEW_CAMPAIGN_LOAD = "ADD_NEW_CAMPAIGN_LOAD"
export const ADD_NEW_CAMPAIGN_ERROR = "ADD_NEW_CAMPAIGN_ERROR"
export const ADD_NEW_CAMPAIGN_SUCCESS = "ADD_NEW_CAMPAIGN_SUCCESS"
export const GET_ALL_MOVIES = "GET_ALL_MOVIES"
export const GET_ALL_GENRE = "GET_ALL_GENRE"

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const EMAIL_COMPAIGN_ERROR = "EMAIL_COMPAIGN_ERROR" 

export const EMAIL_COMPAIGN_TEST_SUCCESS = "EMAIL_COMPAIGN_TEST_SUCCESS" 
export const EMAIL_COMPAIGN_TEST_FAILURE = "EMAIL_COMPAIGN_TEST_FAILURE" 
export const EMAIL_COMPAIGN_TEST_LOADING = "EMAIL_COMPAIGN_TEST_LOADING" 
export const EMAIL_COMPAIGN_RESET = "EMAIL_COMPAIGN_RESET" 

export const EMAIL_CAMPAIGN_DUPLICATE_SUCCESS="EMAIL_CAMPAIGN_DUPLICATE_SUCCESS"
export const EMAIL_CAMPAIGN_DUPLICATE_LOADING="EMAIL_CAMPAIGN_DUPLICATE_LOADING"
export const EMAIL_CAMPAIGN_DUPLICATE_ERROR="EMAIL_CAMPAIGN_DUPLICATE_ERROR"

