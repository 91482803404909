import {
    GET_ALL_JOURNEY,
    ADD_NEW_JOURNEY,
    DELETE_JOURNEY
    } from "./constants";

    
    // **  Initial State
    const initialState = {
      journeyList: []
    };
    
    const journeyReducer = (state = initialState, action) => {
      switch (action.type) {
        case GET_ALL_JOURNEY:
          return {
           ...state,
           journeyList: action.journeyData
          };

        case ADD_NEW_JOURNEY:
          return{
            ...state,
            journeyList: [... state.journeyList, action.payload ]
          }
        case DELETE_JOURNEY:
          return{
            ...state,
            journeyList: state.journeyList.filter(el => el.id !== action.payload)
          }
        default:
          return state;
      }
    };
    
    export default journeyReducer;