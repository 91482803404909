export const USERS_LIST_LOADING = 'USERS_LIST_LOADING'
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS'
export const USERS_LIST_FAILED = 'USERS_LIST_FAILED'

export const ADD_CONTACT_LOADING = 'ADD_CONTACT_LOADING'
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS'
export const ADD_CONTACT_FAILED = 'ADD_CONTACT_FAILED'

export const INSERT_CONTACT_LOADING = "INSERT_CONTACT_LOADING"
export const INSERT_CONTACT_SUCCESS = "INSERT_CONTACT_SUCCESS"
export const INSERT_CONTACT_FAILED = "INSERT_CONTACT_FAILED"